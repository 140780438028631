<template>
  <div class="RightCourse">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span v-if="status == 'off'" class="title">线下课程</span>
        <span v-if="status == 'on'" class="title">线上课程</span>
      </div>
      <div v-if="list.length">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="RightCourseItem"
          @click="goDetail(item)"
        >
          <div class="left">
            <img v-if="status == 'off'" :src="item.coursePic" alt="" />
            <img v-if="status == 'on'" :src="item.pic" alt="" />
          </div>
          <div class="right">
            <div class="name">
              <span v-if="status == 'off'">{{ item.courseName }}</span>
              <span v-if="status == 'on'">{{ item.name }}</span>
            </div>
            <div class="time">
              <span v-if="status == 'off'">授课时长：{{ item.courseDuration }}天</span>
              <span v-if="status == 'on'">讲师：{{ teacherName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <div class="emptyimg"><img src="../../../../assets/teacher/empty.png" alt="" /></div>
        <div class="text">暂无数据</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { offCourse, geteCourseDetail } from '@/api/teacher'
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    teacherId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    teacherName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pager: {
        size: 5,
        current: 1,
        total: 0,
        teacherId: this.teacherId,
      },
      list: [],
    }
  },
  watch: {
    currentIndex: {
      handler(val) {
        console.log(val, this.status)
        if (this.status == 'on') {
          this.onCourseDetailData()
        } else {
          this.offlineCourse()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    async onCourseDetailData() {
      const [res, err] = await to(geteCourseDetail({ ...this.pager, id: this.pager.teacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
    },
    async offlineCourse() {
      const [res, err] = await to(offCourse({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
    },
    goDetail(item) {
      if (this.status == 'off') {
        this.$router.push({
          path: '/teacher/courseDetail',
          query: { offlineCourseId: item.offlineCourseId, teacherId: this.teacherId },
        })
      } else if (this.status == 'on') {
        this.$router.push(`/course/shop/${item.productId}`)
      } else if (this.currentIndex == 1 || this.currentIndex == 2) {
        this.$router.push({
          path: '/teacher/witnessDetail',
          query: { teachingWitnessId: item.teachingWitnessId },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.RightCourse {
  background-color: #fff;
  margin-top: 30px;
  &:first-child {
    margin: 0;
  }
  ::v-deep .el-card__body {
    padding: 0;
  }
  .RightCourseItem {
    cursor: pointer;
    display: flex;
    padding: 20px 13px;
  }
  .left {
    width: 134px;
    height: 68px;
  }
  .right {
    flex: 1;
    margin-left: 14px;
    font-size: 14px;
    .name {
      color: #333333;
      line-height: 20px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .time {
      color: #909399;
      margin-top: 20px;
    }
  }
  .empty {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .emptyimg {
      width: 100px;
      height: 80px;
    }
    .text {
      font-size: 16px;
      color: #909399;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
