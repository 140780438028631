<template>
  <div class="courseDetail">
    <el-card class="box-card">
      <div class="header">
        <div class="left"><img :src="offlineCourseInfo.coursePic" alt="" /></div>
        <div class="right">
          <div class="title">{{ offlineCourseInfo.courseName }}</div>
          <div class="content">{{ offlineCourseInfo.courseDesc }}</div>
          <div class="info">
            <span>课程时长：{{ offlineCourseInfo.courseDuration }} 天</span>
            <span class="teacherName">讲师：{{ offlineCourseInfo.teacherName }}</span>
          </div>
          <div class="btn" @click="handleAbout"><el-button type="primary">预约讲师</el-button></div>
        </div>
      </div>
    </el-card>

    <el-row :gutter="20" class="elRow">
      <el-col :span="18">
        <el-tabs v-model="activeName" class="tabs">
          <el-tab-pane label="课程详情" name="first">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="detail" v-html="offlineCourseInfo.courseIntroduction"></div>
          </el-tab-pane>
          <el-tab-pane label="授课见证" name="second">
            <div class="tabWrap">
              <div class="title">{{ teachingWitnessInfo.teachingWitnessName }}</div>
              <div class="witnessHeader">
                <div class="icon">
                  <img src="../../../assets/teacher/time.png" alt="" />
                  {{ teachingWitnessInfo.createTime }}
                </div>
                <div class="icon">
                  <img src="../../../assets/teacher/eye.png" alt="" />
                  {{ teachingWitnessInfo.pageViews }}
                </div>
              </div>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="detail" v-html="teachingWitnessInfo.teachingWitnessIntroduction"></div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="6">
        <RightCourse
          class="rightCourse"
          :status="'on'"
          :teacher-name="offlineCourseInfo.teacherName"
          :teacher-id="teacherId"
        ></RightCourse>
      </el-col>
    </el-row>
    <Dialog
      ref="dialog"
      :teacher-id="Number(this.$route.query.teacherId)"
      :dialog-title="dialogTitle"
      :dialog-visible="dialogVisible"
      :btn-text="btnText"
      @handleClose="handleClose"
      @success="dialogVisible = false"
    ></Dialog>
  </div>
</template>

<script>
import RightCourse from './components/RightCourse.vue'
import to from 'await-to'
import { offCourseDetail } from '@/api/teacher'
import Dialog from '../components/dialog'
export default {
  components: {
    Dialog,
    RightCourse,
  },
  data() {
    return {
      dialogTitle: '提交预约信息',
      dialogVisible: false,
      btnText: '提交信息',
      offlineCourseInfo: {},
      teachingWitnessInfo: {},
      offlineCourseId: this.$route.query.offlineCourseId,
      teacherId: this.$route.query.teacherId,
      activeName: 'first',
    }
  },
  created() {
    this.offCourseDetailData()
  },
  methods: {
    async offCourseDetailData() {
      const [res, err] = await to(offCourseDetail({ offlineCourseId: this.offlineCourseId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.offlineCourseInfo = res.data.offlineCourseInfo
      this.teachingWitnessInfo = res.data.teachingWitnessInfo
    },
    handleAbout() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    filterHTMLTag(content) {
      if (content !== '' && content !== undefined && content !== null) {
        var msg = content.replace(/<\/?[^>]*>/g, '')
        msg = msg.replace(/[|]*\n/, '') //去除行尾空格
        msg = msg.replace(/&nbsp;/gi, '') //去掉npsp
      } else {
        msg = ''
      }
      return msg
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  .left {
    width: 358px;
    height: 202px;
  }
  .right {
    flex: 1;
    margin-left: 29px;
    .title {
      color: #333333;
      font-size: 24px;
    }
    .content {
      width: 50%;
      color: #606266;
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 50px;
      line-height: 22px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .info {
      color: #606266;
      font-size: 14px;
      .teacherName {
        margin-left: 50px;
      }
    }
    .btn {
      margin-top: 16px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.elRow {
  margin-top: 20px;
  .tabWrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 16px;
      margin-top: 5px;
    }
    .witnessHeader {
      display: flex;
      .icon {
        display: flex;
        align-items: center;
        &:first-child {
          margin-right: 40px;
        }
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
  }
  .tabs {
    background-color: #fff;
    ::v-deep .el-tabs__nav-scroll,
    ::v-deep .el-tabs__nav {
      height: 60px;
    }
    ::v-deep .el-tabs__nav-scroll,
    ::v-deep .el-tabs__content {
      padding-left: 20px;
    }
    ::v-deep .el-tabs__item {
      line-height: 60px;
      font-size: 18px;
    }
    .detail {
      padding: 30px 0;
      color: #3e3e3e;
      font-size: 14px;
    }
  }
}
</style>
